import React from 'react'
import SEO from '../components/seo'
import AffiliatePage from '../components/Pages/Affiliate/Index'
import Layout from '../components/layout'

export default () => {
    return (<Layout>
            <SEO title={"Previewed - Free Mockup Generator for your app"} description={"Previewed is a mockup generator, which can help you create stunning mockups for your app. Customize our ready-made templates to create beautiful mockups in just a few clicks. Join Previewed to access 2D & 3D mockups, images & videos, all latest devices and more."} 
            ogDescription={"Create beautiful mockups for your app, in just a few clicks."} twitterDescription={"Create beautiful mockups for your app, in just a few clicks."}/>
            <AffiliatePage />
            </Layout>)
}   