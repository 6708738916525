import { Link } from 'gatsby'
import React from 'react'
import Button from '../../../Common/Button/Button'
import * as styles from './header.module.scss'

export default () => {
    return(
        <div  className={styles.container}>
            <div className={styles.left}>
            <h1>
                Join our Affiliate Programme
            </h1>
            <h2>
                Earn up to 25% of every purchase made, simply fill out the form and we'll get back to you.
            </h2>
            <Link to={'#contact'}>
                <Button className={styles.joinButton}>
                        Join Now
                </Button>
            </Link>
            </div>
        </div>
    )
}