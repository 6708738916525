import React from 'react';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import API from '../../../../firebase/api';
import Form from '../../../Common/Form/Form';
import * as styles from './contact.module.scss'

export default _ => {
    const validationSchema = Yup.object().shape({
        name: Yup.string()
        .required('Name is required'),
        email: Yup.string()
          .email('Invalid e-mail address.')
          .required('E-mail is required'),
          website: Yup.string().url('This url is not valid.'),
          socials: Yup.string(),
          audience: Yup.number().required('Audience Estimate is required.').positive('Audience Estimate must be positive').integer('Audience Estimate must be an integer.'),
      });
    
      async function onSubmit(values){
          let newValues = {
              name: values.name,
              email: values.email,
              subject: 'Affiliate Application',
              message: `Website - ${values.website}, Socials - ${values.socials}, Audience Number - ${values.audience}`
          }
        if(await API.contactForm(newValues)){
          toast.success('📧 Application Sent')
        }
        else{
          toast.error('Something went wrong...')
        }
      }
    return(
        <div className={styles.container} id={'contact'}>
            <h4>
                Application Form
            </h4>
            <div className={styles.formContainer}>
            <Form validationSchema={validationSchema} onSubmit={onSubmit} initialValues={{
          name: "",
          email: "",
          website: "",
          socials: "",
          audience: "",
        }} fields={[
          {
            placeholder: 'Your Name',
            label: "Name",
            type: 'text',
            name: 'name'
        },
            {
                placeholder: 'example@mail.com',
                label: "E-mail",
                type: 'email',
                name: 'email'
            },
            {
              placeholder: 'www.website.com',
              label: "Website/Blog",
              type: 'text',
              name: 'website'
          },
          {
            placeholder: 'Please provide links to your social pages.',
            label: "Social Pages",
            type: 'textarea',
            name: 'socials'
        },
        {
            placeholder: '~1000',
            label: "Audience Estimate",
            type: 'number',
            name: 'audience'
        },
        ]} 
        submitButtonText={'Send'}
        />
        </div>
        </div>
    )
}