import React from 'react'
import * as styles from './steps.module.scss'

function Card({title, subtitle, index}){
    return(
        <div className={styles.card}>
            <div className={styles.inner}>
                <div className={styles.index}>{index}</div>
                <span className={styles.title}>{title}</span>
                <span className={styles.subtitle}>{subtitle}</span>
            </div>
        </div>
    )
}

export default () => {
    const steps = [
        {
            title: 'Join',
            subtitle: `Contact us about your interest in joining the programme. It's a simple process which should take only a few minutes.`,
        },
        {
            title: 'Invite',
            subtitle: 'Spread the word and invite people to join Previewed.',
        },
        {
            title: 'Profit',
            subtitle: 'Get a fixed percentage every time a user submits a payment.',
        }
    ]

    const Steps = () => steps.map((item, index) => {
        return(
            <Card title={item.title} subtitle={item.subtitle} index={index + 1} />
        )
    })
    return(
        <div className={styles.container}>
            <h4>
                How it works
            </h4>
            <div className={styles.steps}>
                <Steps />
            </div>
        </div>
    )
}