import React from 'react'
import Header from './Header/Header'
import Steps from './Steps/Steps'
import Contact from './Contact/Contact'

export default () => {
    return(
        <>
        <Header />
        <Steps />
        <Contact />
        </>
    )
}